<template>
    <div class="Employees" :class="{ 'is-loading': isFetchingData }">
        <Headline title="Übersicht Ihrer" highlight="Mitarbeiter">
            <p class="Hint">
                {{ filteredEmployees.filter(e => e.isVisible).length }} von
                {{ employees.length }} Mitarbeiter werden angezeigt
            </p>
        </Headline>

        <Toolbar>
            <div></div>
            <div style="align-items: flex-start">
                <div class="ButtonDatePicker">
                    <button
                        class="Button is-plain is-small"
                        variant="plain"
                        size="small"
                        @click="handleGetDriversWithRevenue"
                    >
                        <Spinner v-if="isFetchingRevenueData"></Spinner>

                        <span v-if="!isFetchingRevenueData">
                            Fahrer mit Umsatz auswählen
                        </span>
                    </button>

                    <Datepicker
                        v-if="!isFetchingRevenueData"
                        :startDate="selectedFrom"
                        :endDate="selectedTo"
                        size="small"
                        @onChange="handleDateChange"
                        varinat="inline"
                        :hasQuickActions="false"
                    />
                </div>
                <Button size="small" @onClick="handleShowAddEmployeeModal">
                    <IconPlus slot="iconBefore" width="24px" height="24px"></IconPlus>
                    Mitarbeiter hinzufügen
                </Button>
            </div>
        </Toolbar>

        <employees-table
            :employees="filteredEmployees"
            @onEmployeeClick="handleEditEmployee"
            @onEmployeeVisibilityChange="handleEmployeeVisibilityChange"
        />
        <Modal
            size="medium"
            :title="activeEmployee ? 'Mitarbeiter bearbeiten' : 'Mitarbeiter hinzufügen'"
            :show="modal.employee.isActive"
            :isLoading="isFetchingData"
            :action="{ text: 'Speichern', color: 'green', callback: handleSaveEmployee }"
            :cancel="{ text: 'Abbrechen' }"
            @onModalClose="handleModalClose"
        >
            <Section title="Benötigte Information">
                <div class="Input-Wrap">
                    <Input
                        keyName="name"
                        label="Name"
                        placeholder="Name"
                        direction="vertical"
                        :error="formErrors.name"
                        :value="activeEmployee && activeEmployee.name ? activeEmployee.name : ''"
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="driverPin"
                        label="Fahrer Pin"
                        placeholder="Fahrer Pin"
                        type="text"
                        direction="vertical"
                        :error="formErrors.driverPin"
                        :value="
                            activeEmployee && activeEmployee.driverPin
                                ? activeEmployee.driverPin
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <div>
                        <Input
                            keyName="driverNumber"
                            placeholder="Fahrer Nr."
                            direction="vertical"
                            type="number"
                            min="1"
                            max="9999"
                            :label="isNewEmployee ? 'Fahrer Nr.' : 'Fahrer Nr.'"
                            :isDisabled="!isNewEmployee"
                            :error="formErrors.driverNumber"
                            :value="
                                activeEmployee && activeEmployee.driverNumber
                                    ? activeEmployee.driverNumber
                                    : nextAvailableDriverNumber()[0]
                            "
                            @onKeyPress="handleKeypress"
                        />
                        <div v-if="isNewEmployee" class="Next-Driver-Number-Wrap">
                            <span>Verfügbare Fahrer Nummern</span>
                            <button
                                v-for="number in nextAvailableDriverNumber()"
                                :key="number"
                                class="Next-Driver-Number"
                                @click="handleKeypress({ driverNumber: number })"
                            >
                                {{ number }}
                            </button>
                        </div>
                    </div>
                </div>
            </Section>
            <Section title="Anschrift">
                <div class="Input-Wrap">
                    <Input
                        keyName="streetOne"
                        label="Adresszeile 1"
                        placeholder="Straße"
                        direction="vertical"
                        :error="formErrors.streetOne"
                        :value="
                            activeEmployee && activeEmployee.streetOne
                                ? activeEmployee.streetOne
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="streetTwo"
                        label="Adresszeile 2"
                        placeholder="Adresszusatz"
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.streetTwo
                                ? activeEmployee.streetTwo
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="zipCode"
                        label="PLZ"
                        placeholder="PLZ"
                        direction="vertical"
                        :error="formErrors.zipCode"
                        :value="
                            activeEmployee && activeEmployee.zipCode ? activeEmployee.zipCode : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        keyName="city"
                        label="Stadt"
                        placeholder="Stadt"
                        direction="vertical"
                        :error="formErrors.city"
                        :value="activeEmployee && activeEmployee.city ? activeEmployee.city : ''"
                        @onKeyPress="handleKeypress"
                    />
                </div>
            </Section>
            <Section title="Persönliche Information">
                <div class="Input-Wrap">
                    <DateInput
                        label="Geburtsdatum"
                        direction="vertical"
                        keyName="birthdate"
                        :value="activeEmployeeBirthdate || ''"
                        :error="formErrors.birthdate"
                        @onKeyPress="handleKeypress"
                        @onChange="handleBirthdateChange"
                        format="dd.mm.yyyy"
                        placeholder="TT.MM.JJJJ"
                        :minYear="1900"
                        :maxYear="2010"
                    />
                    <Input
                        keyName="gender"
                        label="Geschlecht"
                        placeholder="Geschlecht"
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.gender ? activeEmployee.gender : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        type="tel"
                        keyName="mobileNumber"
                        label="Telefon"
                        placeholder="Telefon"
                        direction="vertical"
                        :error="formErrors.mobileNumber"
                        :value="
                            activeEmployee && activeEmployee.mobileNumber
                                ? activeEmployee.mobileNumber
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <Input
                        type="email"
                        keyName="emailAddress"
                        label="Email"
                        placeholder="Email"
                        direction="vertical"
                        :error="formErrors.emailAddress"
                        :value="
                            activeEmployee && activeEmployee.email
                                ? activeEmployee.email
                                : activeEmployee && activeEmployee.emailAddress
                                ? activeEmployee.emailAddress
                                : ''
                        "
                        @onKeyPress="handleEmailKeyPress"
                    />
                </div>
            </Section>
            <Section title="Fahrer Information">
                <div class="Input-Wrap">
                    <Input
                        keyName="drivingLicenceNumber"
                        label="Führerschein Nr."
                        placeholder="Führerschein Nr."
                        direction="vertical"
                        :error="formErrors.drivingLicenceNumber"
                        :value="
                            activeEmployee && activeEmployee.drivingLicenceNumber
                                ? activeEmployee.drivingLicenceNumber
                                : ''
                        "
                        @onKeyPress="handleLicenseKeyPress"
                    />
                    <DateInput
                        keyName="driverLicenseDueAt"
                        label="Führerschein Gültig bis"
                        placeholder="Gültig bis"
                        direction="vertical"
                        :error="formErrors.driverLicenseDueAt"
                        :value="
                            activeEmployee && activeEmployee.driverLicenseDueAt
                                ? activeEmployee.driverLicenseDueAt
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />

                    <Input
                        keyName="passengerTransportCertificateNumber"
                        label="P-Schein Nr."
                        placeholder="P-Schein Nr."
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.passengerTransportCertificateNumber
                                ? activeEmployee.passengerTransportCertificateNumber
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />
                    <DateInput
                        keyName="passengerTransportCertificateDueAt"
                        label="P-Schein Gültig bis"
                        placeholder="Gültig bis"
                        direction="vertical"
                        :value="
                            activeEmployee && activeEmployee.passengerTransportCertificateDueAt
                                ? activeEmployee.passengerTransportCertificateDueAt
                                : ''
                        "
                        @onKeyPress="handleKeypress"
                    />

                    <Checkbox
                        @onCheck="handleDriverTypeChange"
                        :value="activeEmployee?.hasNightShiftPreference"
                        label="Nachtfahrer"
                        name="isNightDriver"
                        keyName="isNightDriver"
                    >
                        <p>
                            Nachtfahrer
                        </p>
                        <span class="Checkbox-Description">
                            Hiermit wird der Fahrer als Nachtfahrer markiert
                        </span>
                    </Checkbox>
                </div>
            </Section>
        </Modal>
    </div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import Fuse from 'fuse.js';
import { endOfDay, endOfMonth, format, formatISO, startOfMonth } from 'date-fns';

import Button from '@/components/widgets/Button';
import Checkbox from '@/components/widgets/Checkbox';
import IconPlus from '@/components/icons/IconPlus';
import Headline from '@/components/Headline';
import Input from '@/components/widgets/Input';
import DateInput from '@/components/widgets/DateInput';
import Modal from '@/components/widgets/Modal';
import Toolbar from '@/components/Toolbar';
import Section from '@/components/Section';
import EmployeesTable from './components/EmployeesTable.vue';
import Datepicker from '@/components/widgets/Datepicker.vue';
import Spinner from '@/components/widgets/Spinner.vue';

export default {
    name: 'Employees',
    components: {
        Spinner,
        Datepicker,
        Button,
        Headline,
        IconPlus,
        Input,
        DateInput,
        Modal,
        Toolbar,
        Section,
        EmployeesTable,
        Checkbox,
    },
    data: () => ({
        format,
        isFetchingData: false,
        isFetchingRevenueData: false,
        employees: [],
        employeeWithRevenueButHidden: [],
        filteredEmployees: [],
        formErrors: {
            driverNumber: '',
            driverPin: '',
            name: '',
            birthdate: '',
            emailAddress: '',
            mobileNumber: '',
            streetOne: '',
            city: '',
            zipCode: '',
            drivingLicenceNumber: '',
        },
        currentIndex: null,
        activeEmployee: null,
        isNewEmployee: false,
        selectedFrom: startOfMonth(new Date()),
        selectedTo: endOfMonth(new Date()),
        modal: {
            delete: {
                isActive: false,
            },
            employee: {
                isActive: false,
            },
        },
    }),
    computed: {
        ...mapGetters(['user']),
        isSelfEmployed() {
            const legalForm = this.user.legalForm;
            return legalForm === 'Einzelunternehmer';
        },
        activeEmployeeBirthdate() {
            if (!this.activeEmployee) return '';
            if (!this.activeEmployee.birthdate) return '';
            return format(new Date(this.activeEmployee.birthdate), 'yyyy-MM-dd');
        },
    },
    methods: {
        ...mapActions(['getProfile']),
        resetFormErrors() {
            Object.keys(this.formErrors).forEach(key => {
                this.formErrors[key] = '';
            });
        },
        handleDriverTypeChange(value) {
            this.activeEmployee = {
                ...this.activeEmployee,
                hasNightShiftPreference: !this.activeEmployee.hasNightShiftPreference,
            };
        },
        handleSearch({ query }) {
            if (query.length === 0) {
                this.filteredEmployees = this.employees;
                return;
            }
            const options = {
                minMatchCharLength: 2,
                threshold: Number.isNaN(Number(query)) ? 0.3 : 0.2,
                keys: ['name', 'driverNumber'],
            };
            const fuse = new Fuse(this.employees, options);
            const result = fuse.search(query);
            this.filteredEmployees = result.map(r => r.item).slice(0, 3);
        },
        handleModalClose() {
            this.activeEmployee = null;
            this.currentIndex = null;
            this.modal.delete.isActive = false;
            this.modal.employee.isActive = false;
            this.resetFormErrors();
            this.isNewEmployee = false;
        },
        validateForm() {
            let isValid = true;
            this.resetFormErrors();

            const driverNumber = this.activeEmployee?.driverNumber || '';
            const name = this.activeEmployee?.name || '';
            const pin = this.activeEmployee?.driverPin || '';

            if (!name.trim()) {
                this.formErrors.name = 'Der Name muss angegeben werden';
                this.$toasted.show('Der Name muss angegeben werden', { type: 'error' });
                isValid = false;
            }

            if (!pin.trim()) {
                this.formErrors.driverPin = 'Der Pin muss angegeben werden';
                this.$toasted.show('Der Pin muss angegeben werden', { type: 'error' });
                isValid = false;
            } else if (isNaN(pin)) {
                this.formErrors.driverPin = 'Der Pin muss eine Nummer sein';
                this.$toasted.show('Der Pin muss eine Nummer sein', { type: 'error' });
                isValid = false;
            } else if (pin.length < 4) {
                this.formErrors.driverPin = 'Der Pin muss mindestens 4 Ziffern haben';
                this.$toasted.show('Der Pin muss mindestens 4 Ziffern haben', { type: 'error' });
                isValid = false;
            }

            if (!driverNumber) {
                this.formErrors.driverNumber = 'Die Fahrer Nummer muss angegeben werden';
                this.$toasted.show('Die Fahrer Nummer muss angegeben werden', { type: 'error' });
                isValid = false;
            } else {
                const driverNumberIsTaken = this.employees.find(
                    e =>
                        Number(e.driverNumber) === Number(driverNumber) &&
                        e.id !== this.activeEmployee?.id,
                );

                if (driverNumberIsTaken) {
                    this.formErrors.driverNumber = 'Die Fahrer Nummer ist vergeben';
                    this.$toasted.show('Die Fahrer Nummer ist vergeben', { type: 'error' });
                    isValid = false;
                }
            }

            const email = this.activeEmployee?.email || this.activeEmployee?.emailAddress || '';
            if (email && !this.validateEmail(email)) {
                this.formErrors.emailAddress = 'Ungültige E-Mail-Adresse';
                this.$toasted.show('Ungültige E-Mail-Adresse', { type: 'error' });
                isValid = false;
            }

            return isValid;
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        async handleSaveEmployee() {
            if (!this.validateForm()) {
                return;
            }

            const employeeLocations = JSON.parse(localStorage.getItem('employeeLocations')) || [];
            const driver = employeeLocations.find(e => e.uuid === this.activeEmployee.uuid);

            if (driver) {
                const index = employeeLocations.findIndex(e => e.uuid === this.activeEmployee.uuid);
                employeeLocations[index] = { ...employeeLocations[index], ...this.activeEmployee };
                localStorage.setItem('employeeLocations', JSON.stringify(employeeLocations));
            }

            if (this.isNewEmployee) {
                await this.handleAddEmployee({ ...this.activeEmployee });
            } else {
                await this.handleUpdateEmployee({ ...this.activeEmployee });
            }

            this.getProfile();
            this.handleLoadEmployees();
            this.handleModalClose();
        },
        async handleGetDriversWithRevenue() {
            const queryData = {
                driverNumber: this.selectedEmployee ? this.selectedEmployee.id : null,
                rangeStartAt: this.selectedFrom ? formatISO(this.selectedFrom) : null,
                rangeEndAt: this.selectedTo ? formatISO(endOfDay(this.selectedTo)) : null,
            };

            this.isFetchingRevenueData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });

                const {
                    employeesWithRevenue,
                    employeesWithoutRevenue,
                } = this.calculateDriverRevenue(result.data);

                const promises1 = employeesWithoutRevenue
                    .filter(e => !e.isSystemDriver)
                    .map(employee =>
                        this.handleUpdateEmployee(
                            { ...employee, isVisible: false },
                            { updateUI: false },
                        ),
                    );
                const promises2 = employeesWithRevenue
                    .filter(e => !e.isSystemDriver && !e.isVisible)
                    .map(employee =>
                        this.handleUpdateEmployee(
                            { ...employee, isVisible: true },
                            { updateUI: false },
                        ),
                    );

                if (promises1.length === 0 && promises2.length === 0) {
                    this.$toasted.show('Keine Änderungen erforderlich. Alle Fahrer sind aktuell.', {
                        type: 'info',
                    });
                    return;
                }

                await Promise.all([...promises1, ...promises2]);

                // Reload employees to update UI with current visibility states
                await this.handleLoadEmployees();

                this.$toasted.show(
                    `Es wurden ${promises2.length} Fahrer mit Umsatz auf "Anzeigen" gesetzt.`,
                    {
                        type: 'success',
                    },
                );
                this.$toasted.show(
                    `Es wurden ${promises1.length} Fahrer ohne Umsatz auf "Verstecken" gesetzt.`,
                    {
                        type: 'success',
                    },
                );
            } catch (error) {
                console.error('Error fetching shifts:', error);
                this.$toasted.show('Fehler beim Laden der Fahrer mit Umsatz.', { type: 'error' });
            } finally {
                this.isFetchingRevenueData = false;
            }
        },
        calculateDriverRevenue(shifts) {
            const driverRevenueMap = {};

            shifts.forEach(shift => {
                const driverId = shift.driverNumber;
                if (!driverRevenueMap[driverId]) {
                    driverRevenueMap[driverId] = {
                        driverNumber: driverId,
                        name: shift.driver.name,
                        totalRevenue: 0,
                    };
                }
                driverRevenueMap[driverId].totalRevenue += shift.finalTotalAmount;
            });

            const employeeWithRevenue = Object.values(driverRevenueMap).filter(
                driver => driver.totalRevenue > 0,
            );

            const employeeWithRevenueArr = this.employees.filter(e =>
                employeeWithRevenue.find(d => d.driverNumber === e.driverNumber),
            );

            const employeeWithoutRevenueArr = this.employees.filter(
                e => !employeeWithRevenue.find(d => d.driverNumber === e.driverNumber),
            );

            return {
                employeesWithRevenue: employeeWithRevenueArr,
                employeesWithoutRevenue: employeeWithoutRevenueArr,
            };
        },
        handleDateChange({ from, to }) {
            this.selectedFrom = from;
            this.selectedTo = to;
        },
        handleShowAddEmployeeModal() {
            this.resetFormErrors();
            this.isNewEmployee = true;
            this.activeEmployee = {
                driverNumber: this.nextAvailableDriverNumber()[0],
                isVisible: true,
                hasNightShiftPreference: false,
            };
            this.modal.employee.isActive = true;
        },
        handleBirthdateChange({ from }) {
            if (from) {
                this.activeEmployee = {
                    ...this.activeEmployee,
                    birthdate: format(from, 'yyyy-MM-dd'),
                };
            }
        },
        async handleEmployeeVisibilityChange({ employee }) {
            await this.handleUpdateEmployee({ ...employee, isVisible: !employee.isVisible });
            this.$nextTick(() => {
                this.handleLoadEmployees();
            });
        },
        async handleEditEmployee({ employee }) {
            this.resetFormErrors();

            this.activeEmployee = { ...employee };
            this.isNewEmployee = false;
            this.modal.employee.isActive = true;
        },
        handleKeypress(employee) {
            this.activeEmployee = { ...this.activeEmployee, ...employee };
        },
        handleEmailKeyPress(data) {
            if (data.emailAddress) {
                this.activeEmployee = {
                    ...this.activeEmployee,
                    emailAddress: data.emailAddress,
                    email: data.emailAddress,
                };
            }
        },
        handleLicenseKeyPress(data) {
            if (data.drivingLicenceNumber) {
                this.activeEmployee = {
                    ...this.activeEmployee,
                    drivingLicenceNumber: data.drivingLicenceNumber,
                    driverLicenseNumber: data.drivingLicenceNumber,
                };
            }
        },
        nextAvailableDriverNumber() {
            const driverNumbers = this.employees
                .filter(e => !e.isSystemDriver)
                .map(e => Number(e.driverNumber))
                .filter(num => !isNaN(num))
                .sort((a, b) => a - b);

            if (driverNumbers.length === 0) {
                return [1, 2, 3];
            }

            const availableNumbers = [];
            let candidateNumber = 1;

            while (availableNumbers.length < 3) {
                if (!driverNumbers.includes(candidateNumber)) {
                    availableNumbers.push(candidateNumber);
                }

                candidateNumber++;
            }

            return availableNumbers;
        },
        async handleUpdateEmployee(employee, options = {}) {
            const { updateUI = true } = options;
            this.isFetchingData = true;
            try {
                const updatedEmployee = {
                    ...employee,
                    email: employee.emailAddress || employee.email,
                };

                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/drivers/${employee.driverNumber}`;
                await axios.put(url, updatedEmployee, {
                    withCredentials: true,
                });

                if (updateUI) {
                    const res = await axios.get(
                        `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/simple`,
                        {
                            withCredentials: true,
                        },
                    );
                    const drivers = (res.data?.drivers || []).sort((a, b) =>
                        a?.value.localeCompare(b?.value),
                    );
                    localStorage.setItem('drivers', JSON.stringify(drivers));

                    this.$router.replace({ query: {} });
                    this.$toasted.show('Mitarbeiter wurde erfolgreich aktualisiert', {
                        type: 'success',
                    });
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    const { data } = error.response;
                    if (data.errors) {
                        const errorMessages = Object.values(data.errors).flat();
                        errorMessages.forEach(message => {
                            if (
                                message.message ===
                                'The driver license number has to be 11 characters.'
                            ) {
                                this.$toasted.show(
                                    'Der Führerschein Nr muss 11 Zeichen lang sein',
                                    {
                                        type: 'error',
                                    },
                                );
                            } else {
                                this.$toasted.show('Leider ist ein Fehler aufgetreten', {
                                    type: 'error',
                                });
                            }
                        });

                        if (data.errors.message === 'driverPin must be of type string, got null') {
                            this.formErrors.driverPin = 'Fahrer PIN muss angegeben werden';
                        }
                    } else if (data.message) {
                        this.$toasted.show(data.message, { type: 'error' });
                    }
                } else {
                    this.$toasted.show(
                        'Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut',
                        {
                            type: 'error',
                        },
                    );
                }
                console.error('Error updating employee:', error);
            } finally {
                this.isFetchingData = false;
            }
        },
        async handleAddEmployee(employee) {
            if (!employee.driverNumber) {
                const nextDriverNumber = this.nextAvailableDriverNumber()[0];
                employee.driverNumber = nextDriverNumber;
            }

            const newEmployee = {
                ...employee,
                email: employee.emailAddress || employee.email,
            };

            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/drivers`;
                await axios.post(url, newEmployee, {
                    withCredentials: true,
                });
                this.$toasted.show('Mitarbeiter wurde erfolgreich erstellt', {
                    type: 'success',
                });
            } catch (error) {
                if (error.response && error.response.data) {
                    const { data } = error.response;
                    if (data.errors) {
                        const errorMessages = Object.values(data.errors).flat();
                        errorMessages.forEach(message => {
                            this.$toasted.show(message, { type: 'error' });
                        });
                    } else if (data.message) {
                        this.$toasted.show(data.message, { type: 'error' });
                    }
                } else {
                    this.$toasted.show(
                        'Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut',
                        {
                            type: 'error',
                        },
                    );
                }
                console.error('Error adding employee:', error);
            } finally {
                this.isNewEmployee = false;
                this.isFetchingData = false;
            }
        },
        async handleLoadEmployees() {
            this.isFetchingData = true;
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/drivers`;
                const result = await axios.get(url, {
                    withCredentials: true,
                });

                this.$emit('onFinishLoading');
                this.employees = result.data;
                this.filteredEmployees = result.data;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
                console.error('Error loading employees:', error);
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    async mounted() {
        await this.handleLoadEmployees();

        const uuid = this.$route.query.uuid;
        if (uuid) {
            const employee = this.employees.find(e => e.uuid === uuid);
            if (employee) {
                this.handleEditEmployee({ id: employee.driverNumber });
            }
        }
    },
};
</script>
<style lang="scss">
.Employees {
    @extend %contentWrapper;
    @extend %page;

    .Input-Wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px 20px;
        margin: 0px 0 30px;
        align-items: flex-end;

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            grid-template-columns: 1fr;
        }
    }

    .Search {
        border: solid 1px var(--color-text-inactive);
        border-radius: 4px;
        width: 400px;
        margin: 0;

        .Input {
            padding: 0 12px;
            height: 36px;
        }
    }

    .Next-Driver-Number-Wrap {
        display: flex;
        flex-wrap: wrap;
        margin-top: 4px;
        margin-left: 12px;
        gap: 5px;

        > span {
            font-size: 12px;
            display: block;
            width: 100%;
        }

        .Next-Driver-Number {
            font-size: 12px;
            width: auto;
            color: var(--color-text);
            cursor: pointer;
            border: solid 1px var(--color-text-inactive);
            background-color: transparent;
            border-radius: 4px;
            padding: 4px;
            transition: all 0.2s ease-in-out;
            margin: 0;

            &:focus {
                outline: solid 1px var(--color-blue-dark);
            }

            &:hover {
                color: var(--color-text);
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }

    .ButtonDatePicker {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Button {
            min-width: 205px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .Datepicker .Range,
        .Datepicker .To {
            border: none;
        }
    }
}
</style>
