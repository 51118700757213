var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "EmployeesTable"
  }, [_c('BaseDashboardTable', {
    attrs: {
      "columns": _vm.columns,
      "items": _vm.formattedEmployees,
      "sort": _vm.sortState,
      "empty-message": 'Keine Fahrer gefunden'
    },
    on: {
      "sort-changed": _vm.handleSortChanged,
      "row-click": _vm.handleEmployeeClick
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', [_vm._v("Fahrer")])];
      },
      proxy: true
    }, {
      key: "column-visibility",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('Checkbox', {
          key: "isVisible-".concat(item.driverNumber),
          attrs: {
            "is-disabled": item.isSystemDriver,
            "value": item.isVisible,
            "id": "checkbox-".concat(item.driverNumber)
          },
          on: {
            "onChange": function onChange() {
              return _vm.handleEmployeeVisibilityChange(item.originalEmployee);
            }
          }
        })];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }