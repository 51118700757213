var render = function () {
  var _vm$activeEmployee;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Employees",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', {
    attrs: {
      "title": "Übersicht Ihrer",
      "highlight": "Mitarbeiter"
    }
  }, [_c('p', {
    staticClass: "Hint"
  }, [_vm._v(" " + _vm._s(_vm.filteredEmployees.filter(function (e) {
    return e.isVisible;
  }).length) + " von " + _vm._s(_vm.employees.length) + " Mitarbeiter werden angezeigt ")])]), _c('Toolbar', [_c('div'), _c('div', {
    staticStyle: {
      "align-items": "flex-start"
    }
  }, [_c('div', {
    staticClass: "ButtonDatePicker"
  }, [_c('button', {
    staticClass: "Button is-plain is-small",
    attrs: {
      "variant": "plain",
      "size": "small"
    },
    on: {
      "click": _vm.handleGetDriversWithRevenue
    }
  }, [_vm.isFetchingRevenueData ? _c('Spinner') : _vm._e(), !_vm.isFetchingRevenueData ? _c('span', [_vm._v(" Fahrer mit Umsatz auswählen ")]) : _vm._e()], 1), !_vm.isFetchingRevenueData ? _c('Datepicker', {
    attrs: {
      "startDate": _vm.selectedFrom,
      "endDate": _vm.selectedTo,
      "size": "small",
      "varinat": "inline",
      "hasQuickActions": false
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }) : _vm._e()], 1), _c('Button', {
    attrs: {
      "size": "small"
    },
    on: {
      "onClick": _vm.handleShowAddEmployeeModal
    }
  }, [_c('IconPlus', {
    attrs: {
      "slot": "iconBefore",
      "width": "24px",
      "height": "24px"
    },
    slot: "iconBefore"
  }), _vm._v(" Mitarbeiter hinzufügen ")], 1)], 1)]), _c('employees-table', {
    attrs: {
      "employees": _vm.filteredEmployees
    },
    on: {
      "onEmployeeClick": _vm.handleEditEmployee,
      "onEmployeeVisibilityChange": _vm.handleEmployeeVisibilityChange
    }
  }), _c('Modal', {
    attrs: {
      "size": "medium",
      "title": _vm.activeEmployee ? 'Mitarbeiter bearbeiten' : 'Mitarbeiter hinzufügen',
      "show": _vm.modal.employee.isActive,
      "isLoading": _vm.isFetchingData,
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleSaveEmployee
      },
      "cancel": {
        text: 'Abbrechen'
      }
    },
    on: {
      "onModalClose": _vm.handleModalClose
    }
  }, [_c('Section', {
    attrs: {
      "title": "Benötigte Information"
    }
  }, [_c('div', {
    staticClass: "Input-Wrap"
  }, [_c('Input', {
    attrs: {
      "keyName": "name",
      "label": "Name",
      "placeholder": "Name",
      "direction": "vertical",
      "error": _vm.formErrors.name,
      "value": _vm.activeEmployee && _vm.activeEmployee.name ? _vm.activeEmployee.name : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "driverPin",
      "label": "Fahrer Pin",
      "placeholder": "Fahrer Pin",
      "type": "text",
      "direction": "vertical",
      "error": _vm.formErrors.driverPin,
      "value": _vm.activeEmployee && _vm.activeEmployee.driverPin ? _vm.activeEmployee.driverPin : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('div', [_c('Input', {
    attrs: {
      "keyName": "driverNumber",
      "placeholder": "Fahrer Nr.",
      "direction": "vertical",
      "type": "number",
      "min": "1",
      "max": "9999",
      "label": _vm.isNewEmployee ? 'Fahrer Nr.' : 'Fahrer Nr.',
      "isDisabled": !_vm.isNewEmployee,
      "error": _vm.formErrors.driverNumber,
      "value": _vm.activeEmployee && _vm.activeEmployee.driverNumber ? _vm.activeEmployee.driverNumber : _vm.nextAvailableDriverNumber()[0]
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _vm.isNewEmployee ? _c('div', {
    staticClass: "Next-Driver-Number-Wrap"
  }, [_c('span', [_vm._v("Verfügbare Fahrer Nummern")]), _vm._l(_vm.nextAvailableDriverNumber(), function (number) {
    return _c('button', {
      key: number,
      staticClass: "Next-Driver-Number",
      on: {
        "click": function click($event) {
          return _vm.handleKeypress({
            driverNumber: number
          });
        }
      }
    }, [_vm._v(" " + _vm._s(number) + " ")]);
  })], 2) : _vm._e()], 1)], 1)]), _c('Section', {
    attrs: {
      "title": "Anschrift"
    }
  }, [_c('div', {
    staticClass: "Input-Wrap"
  }, [_c('Input', {
    attrs: {
      "keyName": "streetOne",
      "label": "Adresszeile 1",
      "placeholder": "Straße",
      "direction": "vertical",
      "error": _vm.formErrors.streetOne,
      "value": _vm.activeEmployee && _vm.activeEmployee.streetOne ? _vm.activeEmployee.streetOne : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "streetTwo",
      "label": "Adresszeile 2",
      "placeholder": "Adresszusatz",
      "direction": "vertical",
      "value": _vm.activeEmployee && _vm.activeEmployee.streetTwo ? _vm.activeEmployee.streetTwo : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "zipCode",
      "label": "PLZ",
      "placeholder": "PLZ",
      "direction": "vertical",
      "error": _vm.formErrors.zipCode,
      "value": _vm.activeEmployee && _vm.activeEmployee.zipCode ? _vm.activeEmployee.zipCode : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "city",
      "label": "Stadt",
      "placeholder": "Stadt",
      "direction": "vertical",
      "error": _vm.formErrors.city,
      "value": _vm.activeEmployee && _vm.activeEmployee.city ? _vm.activeEmployee.city : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  })], 1)]), _c('Section', {
    attrs: {
      "title": "Persönliche Information"
    }
  }, [_c('div', {
    staticClass: "Input-Wrap"
  }, [_c('DateInput', {
    attrs: {
      "label": "Geburtsdatum",
      "direction": "vertical",
      "keyName": "birthdate",
      "value": _vm.activeEmployeeBirthdate || '',
      "error": _vm.formErrors.birthdate,
      "format": "dd.mm.yyyy",
      "placeholder": "TT.MM.JJJJ",
      "minYear": 1900,
      "maxYear": 2010
    },
    on: {
      "onKeyPress": _vm.handleKeypress,
      "onChange": _vm.handleBirthdateChange
    }
  }), _c('Input', {
    attrs: {
      "keyName": "gender",
      "label": "Geschlecht",
      "placeholder": "Geschlecht",
      "direction": "vertical",
      "value": _vm.activeEmployee && _vm.activeEmployee.gender ? _vm.activeEmployee.gender : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "type": "tel",
      "keyName": "mobileNumber",
      "label": "Telefon",
      "placeholder": "Telefon",
      "direction": "vertical",
      "error": _vm.formErrors.mobileNumber,
      "value": _vm.activeEmployee && _vm.activeEmployee.mobileNumber ? _vm.activeEmployee.mobileNumber : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "type": "email",
      "keyName": "emailAddress",
      "label": "Email",
      "placeholder": "Email",
      "direction": "vertical",
      "error": _vm.formErrors.emailAddress,
      "value": _vm.activeEmployee && _vm.activeEmployee.email ? _vm.activeEmployee.email : _vm.activeEmployee && _vm.activeEmployee.emailAddress ? _vm.activeEmployee.emailAddress : ''
    },
    on: {
      "onKeyPress": _vm.handleEmailKeyPress
    }
  })], 1)]), _c('Section', {
    attrs: {
      "title": "Fahrer Information"
    }
  }, [_c('div', {
    staticClass: "Input-Wrap"
  }, [_c('Input', {
    attrs: {
      "keyName": "drivingLicenceNumber",
      "label": "Führerschein Nr.",
      "placeholder": "Führerschein Nr.",
      "direction": "vertical",
      "error": _vm.formErrors.drivingLicenceNumber,
      "value": _vm.activeEmployee && _vm.activeEmployee.drivingLicenceNumber ? _vm.activeEmployee.drivingLicenceNumber : ''
    },
    on: {
      "onKeyPress": _vm.handleLicenseKeyPress
    }
  }), _c('DateInput', {
    attrs: {
      "keyName": "driverLicenseDueAt",
      "label": "Führerschein Gültig bis",
      "placeholder": "Gültig bis",
      "direction": "vertical",
      "error": _vm.formErrors.driverLicenseDueAt,
      "value": _vm.activeEmployee && _vm.activeEmployee.driverLicenseDueAt ? _vm.activeEmployee.driverLicenseDueAt : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "passengerTransportCertificateNumber",
      "label": "P-Schein Nr.",
      "placeholder": "P-Schein Nr.",
      "direction": "vertical",
      "value": _vm.activeEmployee && _vm.activeEmployee.passengerTransportCertificateNumber ? _vm.activeEmployee.passengerTransportCertificateNumber : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('DateInput', {
    attrs: {
      "keyName": "passengerTransportCertificateDueAt",
      "label": "P-Schein Gültig bis",
      "placeholder": "Gültig bis",
      "direction": "vertical",
      "value": _vm.activeEmployee && _vm.activeEmployee.passengerTransportCertificateDueAt ? _vm.activeEmployee.passengerTransportCertificateDueAt : ''
    },
    on: {
      "onKeyPress": _vm.handleKeypress
    }
  }), _c('Checkbox', {
    attrs: {
      "value": (_vm$activeEmployee = _vm.activeEmployee) === null || _vm$activeEmployee === void 0 ? void 0 : _vm$activeEmployee.hasNightShiftPreference,
      "label": "Nachtfahrer",
      "name": "isNightDriver",
      "keyName": "isNightDriver"
    },
    on: {
      "onCheck": _vm.handleDriverTypeChange
    }
  }, [_c('p', [_vm._v(" Nachtfahrer ")]), _c('span', {
    staticClass: "Checkbox-Description"
  }, [_vm._v(" Hiermit wird der Fahrer als Nachtfahrer markiert ")])])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }