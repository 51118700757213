<template>
    <div class="EmployeesTable">
        <BaseDashboardTable
            :columns="columns"
            :items="formattedEmployees"
            :sort="sortState"
            :empty-message="'Keine Fahrer gefunden'"
            @sort-changed="handleSortChanged"
            @row-click="handleEmployeeClick"
        >
            <template #title>
                <h3>Fahrer</h3>
            </template>

            <!-- Custom column for checkbox -->
            <template #column-visibility="{ item }">
                <Checkbox
                    :key="`isVisible-${item.driverNumber}`"
                    :is-disabled="item.isSystemDriver"
                    :value="item.isVisible"
                    :id="`checkbox-${item.driverNumber}`"
                    @onChange="() => handleEmployeeVisibilityChange(item.originalEmployee)"
                />
            </template>
        </BaseDashboardTable>
    </div>
</template>

<script type="text/javascript">
import BaseDashboardTable from '@/components/BaseDashboardTable';
import EmptyState from '@/components/EmptyState.vue';
import Checkbox from '@/components/widgets/Checkbox';

export default {
    name: 'EmployeesTable',
    components: {
        BaseDashboardTable,
        EmptyState,
        Checkbox,
    },
    props: {
        employees: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            sortState: {
                column: 'visibility',
                ascending: false,
            },
            columns: [
                { key: 'name', label: 'Fahrer', },
                { key: 'driverNumber', label: 'Fahrer Nr.',},
                {
                    key: 'visibility',
                    label: 'Anzeigen',
                    align: 'left',
                    sortable: true,
                },
            ],
        };
    },
    computed: {
        formattedEmployees() {
            if (!this.employees || this.employees.length === 0) {
                return [];
            }

            return this.sortEmployees(
                this.employees.map(employee => ({
                    name: employee.name || '',
                    driverNumber: employee.driverNumber || '',
                    visibility: employee.isVisible,
                    isVisible: employee.isVisible,
                    isSystemDriver: employee.isSystemDriver,
                    originalEmployee: employee,

                    // Raw values for sorting
                    _name: (employee.name || '').toLowerCase(),
                    _driverNumber: employee.driverNumber,
                    _visibility: employee.isVisible ? 1 : 0,
                })),
            );
        },
    },
    methods: {
        handleSortChanged(sort) {
            this.sortState = {
                column: sort.column,
                ascending: sort.ascending,
            };
        },
        handleEmployeeClick({ item }) {
            // Skip clicks on the checkbox (those are handled separately)
            if (event && event.target.closest('.Checkbox')) {
                return;
            }
            this.$emit('onEmployeeClick', { employee: item.originalEmployee });
        },
        handleEmployeeVisibilityChange(employee) {
            this.$emit('onEmployeeVisibilityChange', { employee });
        },
        sortEmployees(employees) {
            const { column, ascending } = this.sortState;

            return [...employees].sort((a, b) => {
                let aVal, bVal;

                // Select appropriate values for sorting
                if (column === 'name') {
                    aVal = a._name;
                    bVal = b._name;
                } else if (column === 'driverNumber') {
                    aVal = a._driverNumber;
                    bVal = b._driverNumber;

                    // Special handling for numeric driver numbers
                    const aIsNumeric = !isNaN(aVal) && aVal !== '';
                    const bIsNumeric = !isNaN(bVal) && bVal !== '';

                    if (aIsNumeric && bIsNumeric) {
                        aVal = parseFloat(aVal);
                        bVal = parseFloat(bVal);

                        if (aVal < bVal) return ascending ? -1 : 1;
                        if (aVal > bVal) return ascending ? 1 : -1;
                        return 0;
                    }

                    if (aIsNumeric && !bIsNumeric) return ascending ? 1 : -1;
                    if (!aIsNumeric && bIsNumeric) return ascending ? -1 : 1;
                } else if (column === 'visibility') {
                    // Always sort visible items first, regardless of sort direction
                    // But respect the ascending/descending for items with the same visibility
                    if (a.isVisible && !b.isVisible) return -1;
                    if (!a.isVisible && b.isVisible) return 1;

                    // If visibility is the same, sort by name as secondary criteria
                    aVal = a._name;
                    bVal = b._name;
                } else {
                    aVal = a[column];
                    bVal = b[column];
                }

                // Case insensitive comparison for strings
                if (typeof aVal === 'string' && typeof bVal === 'string') {
                    aVal = aVal.toLowerCase();
                    bVal = bVal.toLowerCase();
                }

                // Handle null/undefined values
                if (aVal === null && bVal === null) return 0;
                if (aVal === null) return ascending ? 1 : -1;
                if (bVal === null) return ascending ? -1 : 1;

                if (aVal === undefined && bVal === undefined) return 0;
                if (aVal === undefined) return ascending ? 1 : -1;
                if (bVal === undefined) return ascending ? -1 : 1;

                // Standard comparison
                if (aVal < bVal) return ascending ? -1 : 1;
                if (aVal > bVal) return ascending ? 1 : -1;

                return 0;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.EmployeesTable {
    width: 100%;
}
</style>
